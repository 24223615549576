.clearfix {
  *zoom: 1;
}
.clearfix:before,
.clearfix:after {
  display: table;
  content: '';
}
.clearfix:after {
  clear: both;
}
.button {
  background-color: #41C087;
  line-height: 1.41176471;
  cursor: pointer;
  color: #fff;
  padding: 8px 40px;
  -webkit-appearance: none;
  border-radius: 0;
  box-sizing: border-box;
  text-align: center;
  text-decoration: none;
}
.button:hover,
.button:focus {
  color: #fff;
  background-color: #3aae7a;
}
.button:active {
  background-color: #2d885f;
}
.navigation div.navi {
  float: left;
}
.navigation div.navi > .item {
  float: left;
}
.navigation div.navi > .item > .menu {
  display: block;
}
@media (min-width: 1200px) {
  .togglenavigation,
  .mobile-navigation {
    display: none;
  }
}
.navigation {
  float: left;
  position: relative;
  transition: margin-top 0.4s cubic-bezier(0.87, 0, 0.13, 1);
}
.navigation div.sub2 > .item {
  position: relative;
  margin: 0 0.8vw;
}
.navigation div.sub2 > .item.init {
  margin-left: 0;
}
.navigation div.sub2 > .item.exit {
  margin-right: 0;
}
.navigation div.sub2 > .item:hover > .menu,
.navigation div.sub2 > .item:focus > .menu {
  color: #41C087;
}
.navigation div.sub2 > .item:not(.item-empty) > .menu {
  padding-right: 20px;
  box-sizing: border-box;
}
.navigation div.sub2 > .item:not(.item-empty) > .menu:before {
  content: '';
  position: absolute;
  top: 9px;
  right: 0;
  width: 14px;
  height: 9px;
  transition: all 0.4s cubic-bezier(0.87, 0, 0.13, 1);
  background-size: 100% 100%;
  background-position: 50% 50%;
  background-repeat: no-repeat;
  background-image: url(/images/arrowtoggle-down-blue.svg);
}
.navigation div.sub2 > .item:not(.item-empty) > .menu.path:before {
  background-image: url(/images/arrowtoggle-down-green.svg);
}
.navigation div.sub2 > .item:not(.item-empty):hover > .menu:before,
.navigation div.sub2 > .item:not(.item-empty):focus > .menu:before {
  background-image: url(/images/arrowtoggle-down-green.svg);
}
.navigation div.sub2 > .item.item-empty > .menu:after {
  content: '';
  position: absolute;
  left: 0;
  bottom: 8px;
  height: 5px;
  width: 0;
  transition: all 0.4s cubic-bezier(0.87, 0, 0.13, 1);
  background-color: #41C087;
}
.navigation div.sub2 > .item.item-empty > .menu.path:after {
  width: 100%;
}
.navigation div.sub2 > .item.item-empty:hover > .menu:after,
.navigation div.sub2 > .item.item-empty:focus > .menu:after {
  width: 100%;
}
.navigation div.sub2 > .item > .menu {
  position: relative;
  padding-bottom: 15px;
  box-sizing: border-box;
  font-size: 18px;
  line-height: 1.55555556;
  z-index: 2;
  font-weight: normal;
  font-family: 'BergenText-Bold', sans-serif;
  color: #08407B;
}
.navigation div.sub2 > .item > .menu.path {
  color: #41C087;
}
.navigation div.sub3 {
  position: absolute;
  min-width: 230px;
  max-width: 300px;
  top: 100%;
  left: -25px;
  display: none;
  padding: 0 25px;
  box-sizing: border-box;
  background-color: #FFFFFF;
  box-shadow: 0 0 30px rgba(0, 0, 0, 0.3);
}
.navigation div.sub3 > .item {
  width: 100%;
  position: relative;
  margin-bottom: 19px;
}
.navigation div.sub3 > .item.init {
  margin-top: 19px;
}
.navigation div.sub3 > .item.exit {
  margin-bottom: 27px;
}
.navigation div.sub3 > .item > .menu {
  white-space: nowrap;
  text-align: left;
  color: #41C087;
}
.navigation div.sub3 > .item > .menu:hover,
.navigation div.sub3 > .item > .menu:focus {
  color: #08407B;
}
.navigation div.sub3 > .item > .menu.path {
  color: #08407B;
  font-weight: normal;
  font-family: 'BergenText-Bold', sans-serif;
}
.navigation div.sub2 > .item.exit div.sub3 {
  left: initial;
  right: 0;
}
.navigation div.sub2 > .item.exit div.sub3:after {
  left: initial;
  right: 0;
}
.navigation div.sub2 > .item:hover div.sub3 {
  display: block;
}
.wrapper {
  padding-top: 160px;
}
#home {
  height: 55px;
  margin-top: 8px;
  margin-bottom: 8px;
}
.cb-layout4 #head {
  margin-top: 30px;
  margin-bottom: 90px;
}
@media (max-width: 1023px) {
  .cb-layout4 #head {
    margin-top: 30px;
  }
}
@media (max-width: 767px) {
  .cb-layout4 #head {
    margin-top: 30px;
  }
}
@media (max-width: 1023px) {
  .cb-layout4 #head {
    margin-bottom: 90px;
  }
}
@media (max-width: 767px) {
  .cb-layout4 #head {
    margin-bottom: 90px;
  }
}
.headtop .container {
  margin-top: 7px;
  margin-bottom: 5px;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}
.headcontent .container {
  margin-top: 26px;
  margin-bottom: 26px;
}
.section--multimood .container {
  min-height: 38.0078125vw;
  padding-bottom: 1.953125vw;
}
.cb-layout1 .section--multimood .container {
  padding-bottom: 3.125vw;
}
.cb-layout4 .section--multimood .container {
  min-height: 37.4609375vw;
}
.cb-layout2 .section--multimood .container {
  min-height: 18.359375vw;
}
.cb-layout5 .section--multimood .container {
  min-height: 18.359375vw;
}
.section--multimood .multimood {
  height: 100%;
  position: absolute;
  left: 0;
  top: 0;
}
.section--multimood .content {
  padding-right: 406px;
  box-sizing: border-box;
}
.ctadate {
  right: 3vw;
  bottom: 68px;
}
.ctadate:hover:after,
.ctadate:focus:after {
  opacity: 1;
  transform: scale(1);
}
.service_ctadate {
  width: 188px;
  height: 194px;
  font-size: 22px;
  line-height: 1.18181818;
  padding: 62px 28px 52px 40px;
}
.service_ctadate:before {
  bottom: 22px;
}
.service_ctadate:hover,
.service_ctadate:focus {
  color: #FFFFFF;
}
.service_ctadate:hover:before,
.service_ctadate:focus:before {
  left: calc(50% - 25px);
  width: 51px;
  background-position: 0 50%;
}
body.fr .service_ctadate {
  font-size: 21px;
  padding: 75px 21px 35px 40px;
}
body.en .service_ctadate {
  font-size: 21px;
  padding: 80px 21px 35px 40px;
}
body.it .service_ctadate {
  font-size: 20px;
  padding: 80px 8px 35px 10px;
}
.heroimage {
  position: absolute;
  right: -14.08450704%;
  bottom: 0;
  width: 530px;
  margin-left: 0;
}
.section--six {
  margin-top: 60px;
}
@media (max-width: 1023px) {
  .section--six {
    margin-top: 60px;
  }
}
@media (max-width: 767px) {
  .section--six {
    margin-top: 60px;
  }
}
.section--six .container {
  min-height: 370px;
}
.section--green {
  z-index: 3;
  margin-bottom: 90px;
}
@media (max-width: 1023px) {
  .section--green {
    margin-bottom: 90px;
  }
}
@media (max-width: 767px) {
  .section--green {
    margin-bottom: 90px;
  }
}
.section--green:after {
  content: '';
  position: absolute;
  background-color: #41C087;
  background: linear-gradient(135deg, #41C087 0%, #2DA871 100%);
  left: calc((100vw - 1278px) / 2);
  height: 100%;
  top: 0;
  bottom: 0;
  right: 0;
  z-index: -1;
}
.section--green:before {
  content: '';
  position: absolute;
  width: 844px;
  height: 871px;
  bottom: -170px;
  right: -300px;
}
.desginelement {
  position: absolute;
  float: left;
  pointer-events: none;
  display: block;
}
.desginelement.desginelement--white {
  right: -112px;
  bottom: -236px;
  width: 844px;
  height: 871px;
}
.footarea {
  width: 102.34741784%;
  margin-left: -1.17370892%;
  display: flex;
  flex-wrap: wrap;
}
.footpart {
  margin-left: 1.14678899%;
  margin-right: 1.14678899%;
}
.footpart.footpart--slim {
  width: 31.03975535%;
}
.footpart.footpart--wide {
  width: 97.70642202%;
}
.footbottom {
  min-height: 38px;
}
.wrapper {
  font-size: 18px;
  line-height: 1.33333333;
}
h1 {
  font-size: 70px;
  line-height: 1.07142857;
}
h2 {
  font-size: 40px;
  line-height: 1.125;
}
h3 {
  font-size: 30px;
  line-height: 1.16666667;
}
h4 {
  font-size: 24px;
  line-height: 1.20833333;
}
h5 {
  font-size: 18px;
  line-height: 1.27777778;
}
p.loud {
  font-size: 24px;
  line-height: 1.33333333;
}
p.skew {
  font-size: 30px;
  line-height: 1.16666667;
  min-height: 305px;
  padding-top: 60px;
  box-sizing: border-box;
}
p.skew:after {
  content: '';
  position: absolute;
  left: -25.89073634%;
  top: 0;
  width: 295px;
  height: 305px;
}
.section--one .area {
  width: 100%;
}
.section--one .area > .unit {
  margin-right: 1.14678899%;
  margin-left: 1.14678899%;
  width: 97.70642202%;
}
.section--one .area .cb-album .body,
.section--one .area .head,
.section--one .area .foot,
.section--one .area .part {
  margin-right: 0%;
  margin-left: 0%;
  width: 100%;
}
.section--one .area > .slim {
  width: 22.70642202%;
}
.section--one .area > .slim .head,
.section--one .area > .slim .foot,
.section--one .area > .slim .part {
  margin-right: 0%;
  margin-left: 0%;
  width: 100%;
}
.section--one .area > .slim.cb-album .body {
  margin-right: 0%;
  margin-left: 0%;
  width: 100%;
}
.section--one .area > .slim .tiny {
  width: 100%;
}
.section--two .area {
  width: 100%;
}
.section--two .area > .unit {
  margin-right: 1.72018349%;
  margin-left: 1.72018349%;
  width: 96.55963303%;
}
.section--two .area .cb-album .body,
.section--two .area .head,
.section--two .area .foot,
.section--two .area .part {
  margin-right: 0%;
  margin-left: 0%;
  width: 100%;
}
.section--two .area > .slim {
  width: 46.55963303%;
}
.section--two .area > .slim .head,
.section--two .area > .slim .foot,
.section--two .area > .slim .part {
  margin-right: 0%;
  margin-left: 0%;
  width: 100%;
}
.section--two .area > .slim.cb-album .body {
  margin-right: 0%;
  margin-left: 0%;
  width: 100%;
}
.section--two .area > .slim .tiny {
  width: 100%;
}
.section--one .area {
  width: 102.34741784%;
  margin-left: -1.17370892%;
  display: flex;
  flex-wrap: wrap;
}
.section--two .area {
  width: 103.56294537%;
  margin-left: -1.78147268%;
  display: flex;
  flex-wrap: wrap;
}
.section--six .east {
  width: 65.88419405%;
}
.section--four .area {
  width: 102.34741784%;
  margin-left: -1.17370892%;
  display: flex;
  flex-wrap: wrap;
}
.section--six {
  margin-top: 60px;
}
@media (max-width: 1023px) {
  .section--six {
    margin-top: 60px;
  }
}
@media (max-width: 767px) {
  .section--six {
    margin-top: 60px;
  }
}
.section--slider .side {
  margin-top: 60px;
}
@media (max-width: 1023px) {
  .section--slider .side {
    margin-top: 60px;
  }
}
@media (max-width: 767px) {
  .section--slider .side {
    margin-top: 60px;
  }
}
.section--one .area {
  margin-top: 45px;
  margin-bottom: 45px;
}
@media (max-width: 1023px) {
  .section--one .area {
    margin-top: 45px;
  }
}
@media (max-width: 767px) {
  .section--one .area {
    margin-top: 45px;
  }
}
@media (max-width: 1023px) {
  .section--one .area {
    margin-bottom: 45px;
  }
}
@media (max-width: 767px) {
  .section--one .area {
    margin-bottom: 45px;
  }
}
.cb-layout1 .section--one .area.base {
  margin-top: 90px;
  margin-bottom: 90px;
}
@media (max-width: 1023px) {
  .cb-layout1 .section--one .area.base {
    margin-top: 90px;
  }
}
@media (max-width: 767px) {
  .cb-layout1 .section--one .area.base {
    margin-top: 90px;
  }
}
@media (max-width: 1023px) {
  .cb-layout1 .section--one .area.base {
    margin-bottom: 90px;
  }
}
@media (max-width: 767px) {
  .cb-layout1 .section--one .area.base {
    margin-bottom: 90px;
  }
}
.section--two .area {
  margin-top: 45px;
  margin-bottom: 45px;
}
@media (max-width: 1023px) {
  .section--two .area {
    margin-top: 45px;
  }
}
@media (max-width: 767px) {
  .section--two .area {
    margin-top: 45px;
  }
}
@media (max-width: 1023px) {
  .section--two .area {
    margin-bottom: 45px;
  }
}
@media (max-width: 767px) {
  .section--two .area {
    margin-bottom: 45px;
  }
}
.section--two .area.main {
  margin-top: 0;
}
.cb-layout4 .section--two .area.main {
  margin-top: 90px;
}
@media (max-width: 1023px) {
  .cb-layout4 .section--two .area.main {
    margin-top: 90px;
  }
}
@media (max-width: 767px) {
  .cb-layout4 .section--two .area.main {
    margin-top: 90px;
  }
}
.section--four .area {
  margin-top: 120px;
  margin-bottom: 120px;
}
@media (max-width: 1023px) {
  .section--four .area {
    margin-top: 120px;
  }
}
@media (max-width: 767px) {
  .section--four .area {
    margin-top: 120px;
  }
}
@media (max-width: 1023px) {
  .section--four .area {
    margin-bottom: 120px;
  }
}
@media (max-width: 767px) {
  .section--four .area {
    margin-bottom: 120px;
  }
}
.section--five .area {
  margin-top: 90px;
  margin-bottom: 90px;
}
@media (max-width: 1023px) {
  .section--five .area {
    margin-top: 90px;
  }
}
@media (max-width: 767px) {
  .section--five .area {
    margin-top: 90px;
  }
}
@media (max-width: 1023px) {
  .section--five .area {
    margin-bottom: 90px;
  }
}
@media (max-width: 767px) {
  .section--five .area {
    margin-bottom: 90px;
  }
}
.section--blue .east {
  margin-top: 60px;
  margin-bottom: 60px;
}
@media (max-width: 1023px) {
  .section--blue .east {
    margin-top: 60px;
  }
}
@media (max-width: 767px) {
  .section--blue .east {
    margin-top: 60px;
  }
}
@media (max-width: 1023px) {
  .section--blue .east {
    margin-bottom: 60px;
  }
}
@media (max-width: 767px) {
  .section--blue .east {
    margin-bottom: 60px;
  }
}
.section--one .area .pure.wide .body {
  padding-right: 18.96024465%;
  box-sizing: border-box;
}
.section--one .area .seam.wide,
.section--one .area .edge.wide {
  width: 64.37308869%;
}
.section--one .area .seam.slim,
.section--one .area .edge.slim {
  width: 31.03975535%;
}
.cb-layout1 .section--one .main .edge.slim {
  width: 29.05198777%;
  margin-left: 3.13455657%;
}
.section--one .area .flat.slim {
  width: 31.03975535%;
}
#view .section--one .area .flat.slim {
  padding-bottom: 30px;
}
#view .section--one .area .flat.slim .part.link:last-child .open:hover,
#view .section--one .area .flat.slim .part.link:last-child .open:focus {
  background-position: 0 calc(100% - 8px);
}
.section--one .area .flat.slim .part.text h4 {
  color: #41C087;
  transition: all 0.4s cubic-bezier(0.87, 0, 0.13, 1);
}
.section--one .area .flat.slim .cb-image-container {
  overflow: hidden;
}
.section--one .area .flat.slim .cb-image-container img {
  transition: all 0.4s cubic-bezier(0.05, 0.8, 0.5, 1);
  width: 100%;
  object-fit: cover;
  object-position: center;
}
.section--one .area .flat.slim:hover .part.text h4,
.section--one .area .flat.slim:focus .part.text h4 {
  color: #08407B;
}
.section--one .area .flat.slim:hover .cb-image-container img,
.section--one .area .flat.slim:focus .cb-image-container img {
  transform: scale(1.1);
  opacity: 1;
  width: 100%;
}
.section--two .area .pure.wide .body {
  width: 103.56294537%;
  margin-left: -1.78147268%;
}
.section--two .area .pure.wide .part {
  margin-left: 1.72018349%;
  margin-right: 1.72018349%;
}
.section--two .area .pure.wide .part.tall {
  width: 96.55963303%;
}
.section--two .area .pure.wide .part.tiny {
  width: 21.55963303%;
}
.section--two .area .seam .body {
  padding: 40px 50px;
  box-sizing: border-box;
}
.section--four .area .unit {
  margin-left: 1.14678899%;
  margin-right: 1.14678899%;
}
.section--four .area .unit.wide {
  width: 56.03975535%;
  margin-top: 60px;
}
@media (max-width: 1023px) {
  .section--four .area .unit.wide {
    margin-top: 60px;
  }
}
@media (max-width: 767px) {
  .section--four .area .unit.wide {
    margin-top: 60px;
  }
}
.section--four .area .unit.slim {
  width: 39.37308869%;
}
.section--five .area .unit.wide {
  width: 84.8200313%;
  margin-left: -25.03912363%;
  padding-right: 10.87636933%;
  box-sizing: border-box;
}
@media (max-width: 2050px) {
  .section--five .area .unit.wide {
    width: calc(50% + 6vw);
    margin-left: -6vw;
  }
}
.section--five .area .unit.slim {
  width: 39.51486698%;
  margin-top: 90px;
}
@media (max-width: 1023px) {
  .section--five .area .unit.slim {
    margin-top: 90px;
  }
}
@media (max-width: 767px) {
  .section--five .area .unit.slim {
    margin-top: 90px;
  }
}
@media (max-width: 2050px) {
  .section--five .area .unit.slim {
    width: 50%;
  }
}
/*# sourceMappingURL=./screen-large.css.map */